/**
 * Copyright 2016-2021 Sourcepole AG
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import React from 'react';

export function renderHelp() {
    return <>
    <div style={{padding: '0.5em'}}>
        <p>
            <img src="assets/img/logo.svg" width="120" />
        </p>
        <p>
            <a href="https://github.com/qgis/qwc2-demo-app" target="_blank">Homepage</a><br/>
            <a href="https://github.com/qgis/qwc2-demo-app/tree/master/doc" target="_blank">Documentation</a>
        </p>
        <p>QWC2 Demo build {process.env.BuildDate}</p>
    </div>
    </>;
}
