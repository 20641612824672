/**
 * Copyright 2024 Sourcepole AG
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import ol from 'openlayers';
import PropTypes from 'prop-types';
import { LayerRole, addLayer, addLayerFeatures, changeLayerProperty, removeLayer } from 'qwc2/actions/layers';
import { setCurrentTask } from 'qwc2/actions/task';
import ResizeableWindow from 'qwc2/components/ResizeableWindow';
import 'qwc2/plugins/style/Cyclomedia.css';
import MapUtils from 'qwc2/utils/MapUtils';
import React from 'react';
import GoogleStreetview from 'react-google-streetview';
import { connect } from 'react-redux';
import ConfigUtils from 'qwc2/utils/ConfigUtils';


const Status = {LOGIN: 0, INITIALIZING: 1, INITIALIZED: 2, ERROR: 3, LOADPOS: 4, HAVEPOS: 5};

const options = {
    position: { lat: 47.3452, lng: 8.7089 },
    pov: { heading: 0, pitch: 0 },
    zoom: 1
}
/**
 * Cyclomedia integration for QWC2.
 */
class StreetView extends React.Component {
    static propTypes = {
        active: PropTypes.bool,
        addLayer: PropTypes.func,
        addLayerFeatures: PropTypes.func,
        /** The street-view API key */
        apikey: PropTypes.string,
        changeLayerProperty: PropTypes.func,
        click: PropTypes.object,
        /** Default window geometry with size, position and docking status. Positive position values (including '0') are related to top (InitialY) and left (InitialX), negative values (including '-0') to bottom (InitialY) and right (InitialX). */
        geometry: PropTypes.shape({
            initialWidth: PropTypes.number,
            initialHeight: PropTypes.number,
            initialX: PropTypes.number,
            initialY: PropTypes.number,
            initiallyDocked: PropTypes.bool,
            side: PropTypes.string
        }),
        mapCrs: PropTypes.string,
        mapScale: PropTypes.number,
        projection: PropTypes.string,
        removeLayer: PropTypes.func,
        setCurrentTask: PropTypes.func,
        theme: PropTypes.object
    };
    static defaultProps = {
        geometry: {
            initialWidth: 480,
            initialHeight: 640,
            initialX: 0,
            initialY: 0,
            initiallyDocked: false,
            side: 'left'
        },
        projection: 'EPSG:4326'
    };
    state = {
        status: Status.LOGIN,
        message: "",
        options: null,
        feature: {
            geometry: {
                type: 'Point',
                coordinates: [ 0, 0]
            },
            crs: this.props.projection,
            styleName: 'marker',
            styleOptions: {
                iconSrc: ConfigUtils.getAssetsPath() + '/img/direction.svg',
                color: 'red',
                rotation: 0,
                iconAnchor: [0.5,0.5]
            }
        }
    };
    constructor(props) {
        super(props);
        
    }
    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.active && this.props.active) {
            this.setState({status: this.props.clientId ? Status.INITIALIZING : Status.LOGIN});
        } else if (
            (prevProps.active && !this.props.active) ||
            (prevProps.theme && !this.props.theme)
        ) {
            this.onClose();
        }
        // Handle map click events
        if ((this.props.active || this.state.status === Status.INITIALIZED || this.state.status === Status.HAVEPOS)) {
            const clickPoint = this.queryPoint(prevProps);
            if (clickPoint) {
                this.loadPanoramaPosition(clickPoint)
                if (this.state.status !== Status.LOADPOS) {
                    this.setState({status: Status.LOADPOS});
                    this.props.removeLayer('street-view');
                }
            }
        }
        if (this.state.status === Status.LOGIN && prevState.status > Status.LOGIN) {
            this.props.removeLayer('street-view');
        }
    }
    onClose = () => {
        this.props.setCurrentTask(null);
        this.setState({status: Status.LOGIN});
    };
    render() {
        if (!this.props.active || !this.props.apiKey) {
            return null;
        }
        return (
            <ResizeableWindow dockable={this.props.geometry.side} icon="cyclomedia"
                initialHeight={this.props.geometry.initialHeight} initialWidth={this.props.geometry.initialWidth}
                initialX={this.props.geometry.initialX} initialY={this.props.geometry.initialY}
                initiallyDocked={this.props.geometry.initiallyDocked}
                onClose={this.onClose} splitScreenWhenDocked title="Street View"
                >
                    <div className="layer-info-window-body" role="body">
                        {
                            this.state.options ?
                                <GoogleStreetview 
                                    apiKey={this.props.apiKey} 
                                    streetViewPanoramaOptions={this.state.options}
                                    onPositionChanged={this.panoramaPositionChanged} 
                                    onPovChanged={this.panoramaPositionChanged} 
                                    onVisibleChanged={this.onVisibleChanged}
                                />
                            :
                                <>
                                <p>Seleziona in mappa un punto sulla strada</p>
                                </>
                        }
                    </div>
                
            </ResizeableWindow>
        );
    }
    onVisibleChanged = (ev) => {
        this.setState({status: Status.INITIALIZED});
    }
    panoramaPositionChanged = (posData) => {
        if (this.state.status !== Status.HAVEPOS) {
            this.setState({status: Status.HAVEPOS});
        }
        let feature = this.state.feature;
        if(posData.lng)
            feature = {...feature, 
                geometry: {
                    ...feature.geometry,
                    coordinates: [ posData.lng(),posData.lat()]
                },
                styleOptions: {
                    ...feature.styleOptions,
                    rotation: feature?.styleOptions?.rotation || 0
                }
            };
        else {
            feature = {...feature, 
                geometry: { 
                    ...feature.geometry,
                    coordinates: feature?.geometry?.coordinates || [this.state.options.position.lng, this.state.options.position.lat]
                },
                styleOptions: {
                    ...feature.styleOptions,
                    rotation: posData.heading * (Math.PI/180)
                }
            };
        }
        this.setState({feature: feature})
        const layer = {
            id: "street-view",
            role: LayerRole.MARKER
        };
        this.props.addLayerFeatures(layer, [feature], true);
    };
    loadPanoramaPosition = (posData) => {
        const coordinate = ol.proj.toLonLat(posData.coordinate, this.props.mapCrs)
        this.setState({
            options:{
                position: { 
                    lat: coordinate[1], 
                    lng: coordinate[0]
                },
                pov: { 
                    heading: 0, 
                    pitch: 0 
                },
                zoom: 1
            }
        })
    };
    queryPoint = (prevProps) => {
        if (this.props.click === prevProps.click)  {
            return null;
        }
        return this.props.click;
    };
}


export default connect((state) => ({
    active: state.task.id === "StreetView",
    click: state.map.click,
    mapCrs: state.map.projection,
    mapScale: MapUtils.computeForZoom(state.map.scales, state.map.zoom),
    theme: state.theme.current
}), {
    addLayer: addLayer,
    addLayerFeatures: addLayerFeatures,
    changeLayerProperty: changeLayerProperty,
    removeLayer: removeLayer,
    setCurrentTask: setCurrentTask
})(StreetView);
