/**
 * Copyright 2017-2021 Sourcepole AG
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import shpwrite from "@mapbox/shp-write";
import JSZip from "jszip";

export function customAttributeCalculator(layer, feature) {
    // Here you can dynamically return additional attribute values for the
    // identify dialog, possibly depending on the passed layer and feature.
    // Return a list of <tr> elements i.e.:
    //
    //   return [(
    //       <tr key="custom-attr">
    //           <td className="identify-attr-title"><i>Name</i></td>
    //           <td className="identify-attr-value">Value</td>
    //       </tr>
    //   )];
    return [];
}

export function attributeTransform(name, value, layer, feature) {
    // Here you can transform the attribute value.
    return value;
}
export const customExporters = [
    /*
    {
        id: "myexport",
        title: "My Format",
        allowClipboard: true,
        export: (features, callback) => {
            const data = convertToMyFormat(features);
            callback({
                data: data, type: "mime/type", filename: "export.ext"
            });
        }
    }
    */

    {
        id: "shp",
        title: "SHP",
        allowClipboard: false,
        export: async (features, callback) => {
            let zip = new JSZip();
            const zips = await Promise.all(
                Object.entries(features).map(async (layer) =>
                    await (new JSZip()).loadAsync(
                        await shpwrite.zip(
                            {
                                type: "FeatureCollection",
                                features: layer[1].filter(f => !!f.geometry),
                            },
                            {
                                folder: "/" + layer[0],
                                filename: layer[0],
                                prj: window.qwc2.mapCrs,
                                outputType: "uint8array",
                                compression: "DEFLATE",
                                types: {
                                    point: layer[0],
                                    polygon: layer[0],
                                    polyline: layer[0],
                                    multipolygon: layer[0],
                                    multiline: layer[0],
                                },
                            }
                        )
                    )
                )
            );
            for (let zipObject of zips) {
                zip = await zip.loadAsync(
                    await zipObject.generateAsync({ type: "blob" }),
                    { createFolders: true }
                );
            }
            callback({
                data: await zip.generateAsync({ type: "uint8array" }), type: "application/zip;charset=utf-8", filename: "export.zip"
            });
        }
    }

];
